<style lang="scss" scoped>
@import "./MainHeader.scss";
</style>

<template>
  <div
    :class="{
      biote: isBioteEnv,
    }"
    class="header-wrapper"
  >
    <iframe
      v-if="userType === 'practiceUser' && isCheckoutStep && landingPageType === 'labtest'"
      id="ifr2"
      style="display: none"
      :src="labTestPortal"
      @load="loadLabTestPortal"
    />
    <iframe
      v-if="userType === 'practiceUser' && isCheckoutStep && landingPageType === 'bhrt'"
      id="ifr3"
      style="display: none"
      :src="bhrtPortal"
      @load="loadBhrtPortal"
    />
    <MarketplaceCartDrawer v-if="displayCart && activeDrawer" @toggleDrawer="slideOutDrawer" />
    <LayoutCentered class="header">
      <MainSidebarToggle class="sidebar-toggle" />
      <a @click="backToLandingPage">
        <img class="site-logo" :src="getBrandLogo.img" :alt="getBrandLogo.alt" :title="getBrandLogo.title" />
      </a>
      <!-- <img
        class="simpatra-logo"
        src="@/assets/logo.png"
      
        @click="backToLandingPage"
      /> -->
      <router-link v-if="isLoggedIn && !getSelectedVendor" :to="{ name: 'Home' }" :disabled="disableOrgMenu">
        <div>
          <p v-if="isMarketplace" class="marketplace-txt">Marketplace</p>
          <p v-if="isDosing" class="dosing-txt">IntelliPel BHRT</p>
          <p v-if="isPracticeBuilder" class="marketplace-txt">Practice Builder</p>
          <p v-if="isMedCenterPage" class="marketplace-txt">MedCenter</p>
          <p v-if="isVendorPage" class="marketplace-txt">SellerCenter</p>
        </div>
      </router-link>
      <Searchbar v-if="showSearchBar" class="search" />
      <div class="patient-wrapper">
        <div class="right-group">
          <div
            v-if="displayCart"
            class="cart-container"
            :class="{
              'is-disabled': !isCurrentLocationActive,
            }"
            @click="slideOutDrawer"
          >
            <NavCart class="cart" @locationChanged="toggleLocationSelect" />
            <div
              :disabled="getCheckoutStep || (getOrgDetails && !getOrgDetails.accountSetup && !isSimpatraAdmin)"
              class="credit-container"
            >
              <p class="credit-label">Cart Total</p>
              <p class="credit-amount">$ {{ grandTotal }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- NON LOGGED IN USER -->
      <template v-if="!isLoggedIn">
        <a class="request-demo-link" href="https://calendly.com/simpatra-tech/demo"> Request demo </a>
        <!-- MOBILE AUTH BUTTON -->
        <OAuthLink class="mobile-auth">
          <p class="mobile-sign-in">
            <i class="el-icon-user-solid" />
            <span class="sign-in-text">Sign In</span>
          </p>
        </OAuthLink>
        <OAuthLink class="sign-in"> Sign In </OAuthLink>
      </template>
      <UserSessionActions v-if="isPracticeUserView" class="session-actions" />
    </LayoutCentered>
    <Searchbar class="mobile-search-bar" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueCookies from "vue-cookies";
import { get, isEmpty } from "lodash";
import LayoutCentered from "@/components/common/LayoutCentered.vue";
import NavCart from "@/components/common/NavCart.vue";
import Searchbar from "@/components/common/Searchbar.vue";
import MainSidebarToggle from "@/components/common/MainSidebarToggle.vue";
import OAuthLink from "@/components/common/OAuthLink.vue";
import UserSessionActions from "@/components/common/UserSessionActions.vue";
import MarketplaceCartDrawer from "@/components/marketplace/common/MarketplaceCartDrawer.vue";
import userMe from "@/services/user-me";

export default {
  name: "NewMainHeader",
  components: {
    LayoutCentered,
    NavCart,
    Searchbar,
    MainSidebarToggle,
    OAuthLink,
    UserSessionActions,
    MarketplaceCartDrawer,
  },
  data() {
    return {
      applyLoyalty: false,
      total: 0,
      activeDrawer: false,
      // isPatient: 'false',
      headerBackground: "",
      userType: "",
      practiceId: "",
      contentColor: "",
      landingPageType: "",
      isDosing: false,
      isPracticeBuilder: false,
    };
  },
  computed: {
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    ...mapGetters("locationsModule", ["getCurrentLocationDetails"]),
    ...mapGetters([
      "getCurrentLocationStatus",
      "getCurrentUser",
      "getLoyaltyDollars",
      "getCartItems",
      "getSelectedShippingRates",
      "getCurrentPurchase",
      "getSubstractedPrice",
      "getEmptyCart",
      "getCheckoutStep",
      "getBrandDetails",
      "isLabTestPageLoaded",
      "isBHRTPageLoaded",
      "getLandingPageType",
      "getMyProfileStep",
      "getSelectedVendor",
    ]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    isCurrentLocationActive() {
      return this.getCurrentLocationDetails ? this.getCurrentLocationDetails.isActive : false;
    },
    labTestPortal() {
      return `${process.env.VUE_APP_LABTEST_ROOT}`;
    },
    bhrtPortal() {
      return `${process.env.VUE_APP_BHRT_PORTAL}`;
    },
    isCheckoutStep() {
      if (
        this.$route.name === "PatientProfileInfo" ||
        this.$route.name === "NewCheckout" ||
        this.$route.name === "CheckoutSuccess"
      ) {
        return true;
      }
      return false;
    },
    isMarketplace() {
      if (
        this.$route.name === "MarketplaceDashboardHome" ||
        this.$route.name === "MarketplaceMyStockroomGrid" ||
        this.$route.name === "MarketplaceReOrder" ||
        this.$route.name === "MarketplaceOrderHistory"
      ) {
        return true;
      }
      return false;
    },
    isMedCenterPage() {
      if (
        this.$route.name === "MedCenterHome" ||
        this.$route.name === "MedCenterOrdersLab" ||
        this.$route.name === "MedCenterPatientResults"
      ) {
        return true;
      }
      return false;
    },
    isVendorPage() {
      return this.$route.name.indexOf("vendor") !== -1 || this.$route.name.indexOf("Vendor") !== -1;
    },
    subtotal() {
      let subTotal = 0;
      this.getCartItems.forEach((product) => {
        subTotal += product.price * product.qty;
      });
      return subTotal.toFixed(2);
    },
    shippingAmount() {
      let total = 0;
      this.getSelectedShippingRates.forEach((selectedRate) => {
        total += selectedRate.rate;
      });
      if (total > 0) {
        return total.toFixed(2);
      }
      return (0).toFixed(2);
    },
    grandTotal: {
      get() {
        return this.totalPrice();
      },
    },
    vendorView() {
      return userMe.getMyRoles().isVendorUser || this.getSelectedVendor || false;
    },
    isSimpatraAdmin() {
      const { isSimpatraAdmin } = userMe.getMyRoles();
      return isSimpatraAdmin;
    },
    isPracticeUserView() {
      const selectedVendor = get(this, "getSelectedVendor", null);
      const parentRoute = this.$route.matched[this.$route.matched.length - 2];
      if (isEmpty(selectedVendor) && parentRoute && !parentRoute.meta.simpatraReports) return true;
      return this.isLoggedIn;
    },
    displayCart() {
      if (!this.isLoggedIn) return false;
      const orgHasBeenSetup = this.getOrgDetails?.accountSetup ?? false;
      const isPracticePageView = this.isPracticeUserView && !this.vendorView;
      return !this.getCheckoutStep && orgHasBeenSetup && isPracticePageView;
    },
    showSearchBar() {
      if (!this.isLoggedIn) return true;
      const isPracticePageView = this.isPracticeUserView && !this.vendorView;
      return isPracticePageView;
    },
    disableOrgMenu() {
      const orgHasBeenSetup = this.getOrgDetails && !this.getOrgDetails.accountSetup;
      return orgHasBeenSetup && !this.isSimpatraAdmin;
    },
    isBioteEnv() {
      return process.env.NODE_ENV === "biote";
    },
    getBrandLogo() {
      if (process.env.NODE_ENV === "biote") {
        const logo = require("@/assets/biote/logo.png");
        return {
          img: logo,
          alt: "biote-logo",
          title: "biote marketplace",
        };
      }
      return {
        img: require("@/assets/logo.png"),
        alt: "simpatra-logo",
        title: "simpatra marketplace",
      };
    },
  },
  async mounted() {
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
    this.landingPageType = localStorage.landingPage === "bhrt" ? "bhrt" : "labtest";

    const whitelistDosingPages = ["DosingHome", "DosingPatients", "PatientAppointments", "PatientDetails"];
    const whitelistPracticePages = [
      "PracticeBuilderDashboard",
      "Settings",
      "OnlineStoreMain",
      "PatientOutReachApps",
      "PracticeBuilderLandingPagesDashboard",
      "SocialMediaPageDashboard",
    ];

    this.isDosing = whitelistDosingPages.includes(this.$route.name);
    this.isPracticeBuilder = whitelistPracticePages.includes(this.$route.name);

    this.headerBackground = localStorage.color ? localStorage.color : "#374458";
    this.practiceId = localStorage.practiceId ? localStorage.practiceId : "";
    this.contentColor = localStorage.contentColor ? localStorage.contentColor : "#f05a28";
    const USER_TYPE = this.userType === "practiceUser" ? "patientUser" : "orgUser";

    await this.commitLandingPageType(this.landingPageType);
    await this.commitUserType(USER_TYPE);
    if (window.location.hostname === "checkout.medcenter.health") {
      const data = {
        orgId: this.practiceId,
      };
      await this.fetchBrandingDetails(data);
    }

    if (this.userType === "practiceUser" && localStorage.landingPage !== "bhrt") {
      const productArrayString = VueCookies.get("productArrayString");
      const getItems = JSON.parse(productArrayString);
      await this.commitItemToCart(getItems);
      await this.setEmptyCart(false);
    }

    this.$parent.$on("loyaltySubstract", (result) => {
      this.applyLoyalty = result;
    });
    this.$root.$on("closeCartDrawer", (result) => {
      this.activeDrawer = result;
    });
  },
  methods: {
    ...mapActions({
      fetchActiveCart: "fetchActiveCart",
      createPurchase: "createPurchase",
      substractLoyalty: "substractLoyalty",
      commitItemToCart: "marketplaceProduct/commitItemToCart",
      setEmptyCart: "setEmptyCart",
      fetchBrandingDetails: "fetchBrandingDetails",
      commitlabTestPageLoaded: "commitlabTestPageLoaded",
      commitBhrtPageLoaded: "commitBhrtPageLoaded",
      commitLandingPageType: "commitLandingPageType",
      commitUserType: "commitUserType",
    }),
    loadLabTestPortal() {
      this.commitlabTestPageLoaded();
    },
    loadBhrtPortal() {
      this.commitBhrtPageLoaded();
    },
    slideOutDrawer() {
      if (!this.isCurrentLocationActive) return false;
      this.activeDrawer = !this.activeDrawer;
    },
    backToLandingPage() {
      this.$router.push("/");
      // if (this.getMyProfileStep) {
      //   Message({
      //     type: "warning",
      //     showClose: true,
      //     message: "Please add profile details",
      //   });
      // }
      // if (this.landingPageType === "bhrt" && !this.getMyProfileStep) {
      //   this.backToBhrtPage();
      // }
      // if (this.landingPageType === "labtest" && !this.getMyProfileStep) {
      //   this.backToLabTest();
      // }
    },
    backToLabTest() {
      if (this.isLabTestPageLoaded || this.$route.name === "Product") {
        const iframe = "ifr2";
        this.postMessage(iframe);
        window.location = `${process.env.VUE_APP_LABTEST_ROOT}/simpatra`;
      }
    },
    backToBhrtPage() {
      if (this.isBHRTPageLoaded) {
        const iframe = "ifr3";
        this.postMessage(iframe);
        window.location = `${process.env.VUE_APP_BHRT_PORTAL}/simpatra`;
      }
    },
    postMessage(iframe) {
      const userEmail = localStorage.email !== "" ? localStorage.email : this.getCurrentUser.email;
      const postMsg = {
        access_token: localStorage.access_token,
        id_token: localStorage.id_token,
        email: userEmail,
        exp: localStorage.exp,
        userInfo: this.getCurrentUser,
      };
      this.postCrossDomainMessage(postMsg, iframe);
    },
    postCrossDomainMessage(msg, iframe) {
      const win = document.getElementById(iframe).contentWindow;
      const PATH = iframe === "ifr3" ? `${process.env.VUE_APP_BHRT_PORTAL}` : `${process.env.VUE_APP_LABTEST_ROOT}`;
      win.postMessage(msg, PATH);
    },
    totalPrice() {
      if (!this.getEmptyCart) {
        if (!this.applyLoyalty) {
          if (this.getCurrentPurchase.appliedRebate) {
            // eslint-disable-next-line
            return (
              Number(this.subtotal) + Number(this.shippingAmount) - this.getCurrentPurchase.appliedRebate.toFixed(2)
            );
          }
          return (Number(this.subtotal) + Number(this.shippingAmount)).toFixed(2);
        }
        return this.loyaltySubstract();
      }
      return "0.00";
    },
    loyaltySubstract() {
      if (this.getCurrentPurchase.appliedRebate) {
        // eslint-disable-next-line
        return (
          Number(this.subtotal) +
          Number(this.shippingAmount) -
          this.getCurrentPurchase.appliedRebate +
          Number(this.getLoyaltyDollars)
        ).toFixed(2);
      }
      return (Number(this.subtotal) + Number(this.shippingAmount) - Number(this.getLoyaltyDollars)).toFixed(2);
    },
    async toggleLocationSelect(id) {
      await this.createPurchase(id);
      await this.fetchActiveCart(id);
    },
  },
};
</script>
